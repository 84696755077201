


body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

body.dark-mode {
  background-color: #2d3436;
  color: #dfe6e9;
}

body.light-mode {
  background-color: #dfe6e9;
  color: #2d3436;
}

body:not(.dark-mode-preloading) {
  transition: all 0.3s linear;
}